import { StructuredText } from 'react-datocms';

import Social from 'components/Social';
import { HeadingSmall } from 'components/UI/Headings';

import { Wrapper, Phone } from './styles';

const Content = ({ pageInfo, messengers, translations, networks }) => {
  const { phone, email } = translations || {};

  return (
    <Wrapper>
      <StructuredText data={pageInfo?.value} />
      <HeadingSmall href={`mailto:${email}`} as="a">
        {email}
      </HeadingSmall>
      <Phone>
        <HeadingSmall href={`tel:${phone}`} as="a">
          {phone}
        </HeadingSmall>
        <span>{messengers}</span>
      </Phone>
      <Social networks={networks} />
    </Wrapper>
  );
};

export default Content;
