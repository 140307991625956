import styled from 'styled-components';

import { HeadingSmall } from 'components/UI/Headings';

const Wrapper = styled.div`
  max-width: 26.5rem;
  margin-top: 0.5rem;
  row-gap: 1.5rem;
  display: grid;

  ${HeadingSmall} {
    color: var(--primary-color);
    margin-bottom: 0;

    &:hover {
      color: var(--primary-button-hover);
    }

    &:active {
      color: var(--primary-button-active);
    }
  }
`;

const Phone = styled.div`
  display: grid;

  span {
    font-size: 0.75rem;
    color: var(--button-text-color);
  }
`;

export { Wrapper, Phone };
