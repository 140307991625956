import { graphql } from 'gatsby';

import HelpRequest from 'components/HelpRequest';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer } from 'components/UI/Containers';
import {
  HeadingBig,
  HeadingMedium,
  HeadingSmall,
} from 'components/UI/Headings';

import Content from './Content';

const ContactsPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsContactsPage: pageData,
    datoCmsHowToRequest: requestData,
    datoCmsMiscTextString: translations,
    datoCmsFooter: { contacts: networks },
  } = data;
  const {
    seo,
    title,
    contactTitle,
    pageInfo,
    messengersList: messengers,
  } = pageData;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <div>
            <HelpRequest content={requestData} />
            <div>
              <HeadingMedium as="h2">{contactTitle}</HeadingMedium>
              <Content {...{ pageInfo, messengers, translations, networks }} />
            </div>
          </div>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default ContactsPageTemplate;

export const query = graphql`
  query ContactsPageQuery($locale: String!) {
    datoCmsContactsPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      contactTitle
      pageInfo {
        value
      }
      messengersList
    }
    datoCmsHowToRequest(locale: { eq: $locale }) {
      title
      instruction {
        value
      }
      applyRequestButton
      applyRequestForm
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      locale
      phone
      email
    }
    datoCmsFooter(locale: { eq: $locale }) {
      contacts {
        fieldName
        isSocial
        links {
          id: originalId
          fieldName
          fieldValue
        }
      }
    }
  }
`;
